.navbar {
    --navbarNavFontSize: calc((16 / 16) * 1rem);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    width: var(--space2XL);
    position: fixed;
    top: var(--spaceOuter);
    left: var(--spaceOuter);
    bottom: var(--spaceOuter);
    z-index: 1024;

    @media (--mediaMobile) {
        & {
            --navbarNavFontSize: calc((22 / 16) * 1rem);
        }
    }

    @media (--mediaMobile), (max-height: 696px) {
        & {
            bottom: auto;
        }
    }
}

.navbar__logo {
    display: flex;
    position: relative;
    padding: var(--spaceS) var(--spaceS) var(--spaceL);
    z-index: 16;

    &:focus {
        box-shadow: 0 0 0 4px rgb(var(--rgbBackground)), 0 0 0 8px rgb(var(--rgbText));
        outline: none;
    }

    &:active {
        box-shadow: none;
    }

    @media (--mediaMobile) {
        & {
            padding-bottom: var(--spaceS);
        }
    }
}

.navbar__nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex: 1 1 auto;
    max-width: var(--space2XL);
    position: relative;
    top: calc(var(--spaceM) * -1);

    @media (--mediaMobile), (max-height: 696px) {
        & {
            display: none;
        }
    }
}

.navbar__nav-list {
    transform: rotate(-90deg) translate3d(-50%, 0, 0);
    display: flex;
    flex-direction: row-reverse;
}

.navbar__nav-link {
    padding: var(--spaceM);
    color: rgb(var(--rgbText) / 0.8);
    font-weight: var(--fontWeightMedium);
    font-size: var(--navbarNavFontSize);
    text-decoration: none;
    position: relative;
    transition: color var(--durationS) ease 0.1s;
    line-height: 1;

    &:hover,
    &:active,
    &:focus,
    &[aria-current='page'] {
        color: var(--colorTextBody);
    }

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: var(--spaceS);
        left: var(--spaceS);
        height: 4px;
        background: rgb(var(--rgbAccent));
        transform: scaleX(0) translateY(-2px);
        transform-origin: right;
    }

    @media (--mediaUseMotion) {
        &::after {
            transition: transform var(--durationM) var(--bezierFastoutSlowin);
        }
    }

    &:hover:after,
    &:active:after,
    &:focus:after,
    &[aria-current='page']:after {
        transform: scaleX(1) translateY(-2px);
        transform-origin: left;
    }

    &:focus {
        box-shadow: 0 0 0 4px rgb(var(--rgbBackground)), 0 0 0 8px rgb(var(--rgbText));
        outline: none;
    }

    &:active {
        box-shadow: none;
    }
}

.navbar__nav-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 16;

    @media (--mediaMobile), (max-height: 696px) {
        & {
            flex-direction: row;
            position: absolute;
            bottom: var(--spaceXL);
            left: var(--spaceXL);
        }
    }

    @media (max-width: 820px) and (max-height: 420px) {
        & {
            left: var(--spaceL);
            transform: none;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }
}

.navbar__nav-icon-link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--spaceS);
    width: var(--space2XL);
    height: var(--space2XL);
    color: var(--colorTextLight);
    transition: color var(--durationM) var(--bezierFastoutSlowin);

    &:focus {
        box-shadow: 0 0 0 4px rgb(var(--rgbBackground)), 0 0 0 8px rgb(var(--rgbText));
        outline: none;
    }

    &:active {
        box-shadow: none;
    }

    &:hover,
    &:focus,
    &:active {
        color: rgb(var(--rgbAccent));
    }
}

.navbar__nav-icon {
    fill: currentColor;
    transition: fill var(--durationM) ease;
}

.navbar__mobile-nav {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgb(var(--rgbBackground) / 0.9);
    transform: translate3d(0, -100%, 0);
    transition-property: background;
    transition-duration: var(--durationL);
    transition-timing-function: var(--bezierFastoutSlowin);
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(16px);

    @media (--mediaMobile), (max-height: 696px) {
        & {
            display: flex;
        }
    }

    @media (--mediaUseMotion) {
        & {
            transition-property: transform, background;
            transform: translate3d(0, -100%, 0);
        }
    }
}

.navbar__mobile-nav--entered {
    transform: translate3d(0, 0, 0);
}

.navbar__mobile-nav-link {
    width: 100%;
    font-size: var(--navbarNavFontSize);
    text-align: center;
    text-decoration: none;
    color: var(--colorTextBody);
    padding: var(--spaceL);
    opacity: 0;
    transition-property: opacity;
    transition-duration: var(--durationS);
    transition-timing-function: var(--bezierFastoutSlowin);
    position: relative;
    top: calc(var(--spaceM) * -1);

    @media (--mediaUseMotion) {
        & {
            transition-property: transform, opacity;
            transform: translate3d(0, calc(var(--spaceXL) * -1), 0);
        }
    }

    @media (max-width: 820px) and (max-height: 420px) {
        & {
            top: auto;
        }
    }

    @media (max-width: 400px), (max-height: 360px) {
        & {
            --navbarNavFontSize: calc((18 / 16) * 1rem);
        }
    }

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: var(--space3XL);
        left: var(--space3XL);
        height: 4px;
        background: rgb(var(--rgbAccent));
        transform: scaleX(0) translateY(-1px);
        transform-origin: right;
    }

    @media (--mediaUseMotion) {
        &::after {
            transition: transform var(--durationM) var(--bezierFastoutSlowin);
        }
    }

    &:hover:after,
    &:active:after,
    &:focus:after {
        transform: scaleX(1) translateY(-1px);
        transform-origin: left;
    }

    &:focus {
        box-shadow: 0 0 0 4px rgb(var(--rgbBackground)), 0 0 0 8px rgb(var(--rgbText));
        outline: none;
    }

    &:active {
        box-shadow: none;
    }
}

.navbar__mobile-nav-link--entered {
    opacity: 1;
    transform: none;
}

.loader {
    display: flex;
    justify-content: center;
    width: var(--size);
    height: var(--size);
}

.loader-text {
    color: inherit;
    font-size: var(--fontSizeBodyS);
    font-weight: var(--fontWeightMedium);
}

.loader__content {
    display: grid;
    grid-template-columns: repeat(3, var(--spanSize));
    gap: var(--gapSize);
    align-items: center;
    justify-content: center;
    transform: skewX(22deg);
}

@keyframes loader-span {
    0% {
        transform: scaleY(0);
        opacity: 0.5;
        transform-origin: top;
    }
    40% {
        transform: scaleY(1);
        opacity: 1;
        transform-origin: top;
    }
    60% {
        transform: scaleY(1);
        opacity: 1;
        transform-origin: bottom;
    }
    100% {
        transform: scaleY(0);
        opacity: 0.5;
        transform-origin: bottom;
    }
}

.loader__span {
    height: 60%;
    background: currentColor;
    animation: loader-span 1s var(--bezierFastoutSlowin) infinite;
    transform: scaleY(0);
    transform-origin: top left;

    &:nth-child(2) {
        animation-delay: 0.1s;
    }

    &:nth-child(3) {
        animation-delay: 0.2s;
    }
}
